var funciones = {
  jsonToArray (json) {
    var result = []
    var keys = Object.keys(json)
    keys.forEach(function (key) {
      result.push(json[key])
    })
    return result
  },
  selectArrayItemById (array, valor) {
    var select = null
    array.forEach((item) => {
      if (typeof (valor) === 'string') {
        if (item.id.toString() === valor) {
          select = item
        }
      } else {
        if (item.id === valor) {
          select = item
        }
      }
    })
    return select
  },
  selectArrayItemByField (array, valor, field) {
    var select = null
    array.forEach((item) => {
      if (typeof (valor) === 'string') {
        if (item[field].toString() === valor) {
          select = item
        }
      } else {
        if (item[field] === valor) {
          select = item
        }
      }
    })
    return select
  },
  observerToArray (observer) {
    var result = []
    for (let i = 0; i < observer.length; i += 1) {
      if (typeof (observer[i]) === 'object') {
        try {
          result[i] = JSON.parse(JSON.stringify(observer[i]));
        } catch (e) {
          console.error(e);
        }
      }
    }
    return result
  },
  groupJSON(array, group, prop) {
    var nuevoArray    = []
    var arrayTemporal = []
    for(var i=0; i<array.length; i++) {   
      if(prop) {
        arrayTemporal = nuevoArray.filter(resp => resp["reference"] == array[i][group][prop])
      } else {
        arrayTemporal = nuevoArray.filter(resp => resp["reference"] == array[i][group])
      }        

      if(arrayTemporal.length>0){
          nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]["group"].push(
            array[i]
          )
      }else{
          nuevoArray.push({
            "reference" : array[i][group][prop], 
            'data': array[i][group],
            "group" : [array[i]]
          })
      }      
    }
  
    return nuevoArray
  },
  groupBy(array, f) {
    const groups = {};
    array.forEach((o) => {
      const group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    })
    return Object.keys(groups).map((group) => {
      return groups[group];
    });
  },
  sortArrayDate(data, key, orden) {
    if (orden!=-1 && orden!=1) orden=1;
    data.sort(function(a,b){
      var dateA=new Date(a[key]),dateB=new Date(b[key]);
      return (dateA-dateB)*orden;
    })  
  },
  sortArrayInteger(data, key, orden) {    
    if (orden!=-1 && orden!=1) orden=1;
    data.sort(function(a,b){      
      var intA=a[key],intB=b[key];
      return (intA-intB)*orden;
    }) 
    return data 
  },  
  sortArrayIntegerMultiField(data, keys, orden) {    
    if (orden!=-1 && orden!=1) orden=1;
    data.sort(function(a,b){      
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const valA = a[key];
        const valB = b[key];
  
        if (valA !== valB) {
          return (valA - valB) * orden;
        }
      }
      return 0;
    }) 
    return data 
  },    
  sortArrayString(data, key, orden) {        
    if (orden!=-1 && orden!=1) orden=1;

    if(orden == 1) {
      data.sort(function(a, b){
        var nameA=a[key].toLowerCase(), nameB=b[key].toLowerCase();      
        if (nameA < nameB) //sort string ascending
          return -1;
        if (nameA > nameB)
          return 1;
        return 0; //default return value (no sorting)
      });
    } 
    if(orden == -1) {
      data.sort(function(a, b){
        var nameA=a[key].toLowerCase(), nameB=b[key].toLowerCase();      
        if (nameA > nameB)
          return -1;
        if (nameA < nameB)
          return 1;
        return 0; 
      });      
    }
    return data
  },
  invertColor(hex) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
        g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
        b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b);
  },    
  padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  },
  slugify (text, ampersand = 'and') {
    const a = 'àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ'
    const b = 'aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh'
    const p = new RegExp(a.split('').join('|'), 'g')

    return text.toString().toLowerCase()
      .replace(/[\s_]+/g, '-')
      .replace(p, c =>
        b.charAt(a.indexOf(c)))
      .replace(/&/g, `-${ampersand}-`)
      .replace(/[^\w-]+/g, '')
      .replace(/--+/g, '-')
      .replace(/^-+|-+$/g, '')
  },   
  
  exportToCSV (arrayOfObjects, downloadName) {
    if (!arrayOfObjects.length) {
      return;
    }

    var csvContent = "data:text/csv;charset=utf-8,";

    // headers
    csvContent += this.objectToCSVRow(Object.keys(arrayOfObjects[0]));

    arrayOfObjects.forEach((item)=>{            
      csvContent += this.objectToCSVRow(item);
    }); 

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", downloadName+".csv");
    document.body.appendChild(link); 
    link.click();
    document.body.removeChild(link); 
  },  
  objectToCSVRow (dataObject) {    
    var dataArray = new Array;
    for (var o in dataObject) {
      var innerValue = dataObject[o]===null?'':dataObject[o].toString();
      innerValue = innerValue.replace(/#/g, '');
      dataArray.push(innerValue);      
    }    
    return dataArray.join(';') + '\r\n';    
  },
  toFixed(value, precision) {    
    return parseFloat(parseFloat(value).toFixed(precision))
  }
}

export default funciones
