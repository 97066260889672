<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Proyectos</h4>
            <div class="small text-muted">Administración de proyectos</div>
          </b-col>

          <b-col sm="5">
            <b-button v-if="!isEmployee" @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Proyecto">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

            <div>
              <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
                <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                  <i class="fa fa-filter"></i> Mostrar Filtros
                </b-button>
              </b-col>
              <b-col sm="12" class="d-none d-md-block" v-else>
                <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                  <i class="fa fa-window-close"></i>
                </b-button>
              </b-col>
            </div>
          </b-col>  
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <div>
          <b-row v-if="table.mostrarFiltros" class="mb-3">
            <b-col sm="2">
              <v-select :options="arr.select.customers" v-model="filters.customers" placeholder="Clientes" :multiple="true" :select-on-tab="true"></v-select>
            </b-col>
            <b-col sm="2" v-if="!isEmployee">
              <v-select :options="arr.select.employees" v-model="filters.employees" placeholder="Project Manager" :multiple="true" :select-on-tab="true"></v-select>
            </b-col>          
            <b-col sm="2">
              <v-select :options="arr.select.status" v-model="filters.status" placeholder="Estado" :multiple="true" :select-on-tab="true"></v-select>
            </b-col>        
            <b-col sm="3">
              <b-form-datepicker v-model="filters.date_end" 
                                  placeholder="Fecha de Finalización" 
                                  today-button
                                  reset-button
                                  close-button>
              </b-form-datepicker>                      
            </b-col>
            <b-col sm="2">
              <b-button variant="outline-dark" @click="filterProjects()">
                <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                Filtrar
              </b-button>
            </b-col>          
          </b-row>
        </div>

        <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">

          <b-tab :title="getNameTab('with_expiration')" :active="tabs.with_expiration" @click="setActiveTab('with_expiration')">
            <b-row>
              <b-col>
                <b-table class="mb-0 crud-project-table"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableWithExpiration.items"
                        :fields="tableWithExpiration.fields"                    
                        :current-page="tableWithExpiration.currentPage"
                        :per-page="tableWithExpiration.perPage"
                        selectable
                        select-mode="single"
                        @row-selected="onRowSelectedWithExpiration"                    
                        v-if="tableWithExpiration.items.length">

                    <template v-slot:table-colgroup="scope">
                        <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                        >
                    </template> 

                    <template v-slot:row-details="row">
                      <b-card>                    
                        <b-row>
                          <b-col md="6" class="mt-4">    
                            <div v-if="row.item.status=='active'">         
                              <div v-html="diffDateHumanize(row.item.date_end)"/>
                            </div>
                            <div v-else>
                              <b-alert show variant="warning" v-if="row.item.status=='pause'">El proyecto está pausado</b-alert>
                              <b-alert show variant="danger" v-if="row.item.status=='canceled'">El proyecto esta cancelado</b-alert>
                              <b-alert show variant="success" v-if="row.item.status=='finished'">El proyecto está finalizado</b-alert>
                            </div>
                            
                            <hr>

                            <div v-if="row.item.hours_estimated > sumTotalHours(arr.projectsHours)">
                              <h5>Se consumió el {{porcentajeHorasRestantes(sumTotalHours(arr.projectsHours), row.item.hours_estimated).toFixed(2)}}% de horas</h5>
                              <b-progress :max="row.item.hours_estimated" height="2rem">  
                                <b-progress-bar :value="sumTotalHours(arr.projectsHours)">
                                  Horas: <strong>{{ sumTotalHours(arr.projectsHours) }} / {{ row.item.hours_estimated }}</strong>
                                </b-progress-bar>
                              </b-progress>                        
                            </div>
                            <div v-else>
                              <div v-if="row.item.hours_estimated == sumTotalHours(arr.projectsHours)">                                                    
                                <b-alert variant="warning" show>
                                  <b>
                                    Se consumieron todas las horas estimadas 
                                  </b>
                                </b-alert>            
                              </div>
                              <div v-else>
                                <b-alert variant="danger" show>
                                  <b>
                                    Se exedió {{(sumTotalHours(arr.projectsHours) - row.item.hours_estimated)}} horas sobre el estimado de {{row.item.hours_estimated}} horas
                                  </b>
                                </b-alert>                          
                              </div>
                            </div>
                          </b-col>

                          <b-col md="6">                                          
                            <b-table-simple hover small caption-top responsive>
                              <caption>Resumen de las horas consumidas por cada empleado:</caption>
                              <b-thead head-variant="dark">
                                <b-tr>
                                  <b-th>Empleado</b-th>
                                  <b-th class="text-center">Horas Consumidas</b-th>
                                  <b-th class="text-right">Costo Prom x Hora</b-th>
                                  <b-th class="text-right">Costo Total</b-th>                            
                                </b-tr>
                              </b-thead>
                              <b-tbody>
                                <b-tr v-for="(item, index) in arr.projectsHours" :key="index">
                                  <b-td>{{item.data.name}}</b-td>
                                  <b-td class="text-center">{{sumTotalHoursByEmployee(item.group)}}</b-td>
                                  <b-td class="text-right">{{Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format( costPromHoursEmployee(item.group) )}}</b-td>
                                  <b-td class="text-right">{{Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format( costTotalHoursEmployee(item.group) )}}</b-td>
                              </b-tr>
                              </b-tbody>
                              <b-tfoot>
                                <b-tr>
                                  <b-td colspan="7" variant="secondary" class="text-right">
                                    Total horas: <b>{{ sumTotalHours(arr.projectsHours) }}</b>
                                  </b-td>
                                </b-tr>
                                <b-tr>
                                  <b-td colspan="7" variant="secondary" class="text-right">
                                    Promedio de horas: <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format(promTotalHours(arr.projectsHours))}} </b>
                                  </b-td>
                                </b-tr>                            
                                <b-tr>
                                  <b-td colspan="7" variant="secondary" class="text-right">                                
                                    Total costo: <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format(sumTotalCost(arr.projectsHours))}}</b>
                                  </b-td>
                                </b-tr>
                              </b-tfoot>
                            </b-table-simple>                           
                          </b-col>
                        </b-row>
                      </b-card>
                    </template>

                    <template v-slot:cell(id)="data">
                      <b>{{ data.item.id }}</b>
                    </template>

                    <template v-slot:cell(name)="data">
                      <div v-b-popover.hover.top="data.item.description" title="Descripción" v-if="data.item.description">                    
                        <b-icon icon="card-text" class="mr-1" style="font-size:17px;"></b-icon>
                        {{ data.item.name }}                    
                      </div>                  
                      <div v-else>
                        {{ data.item.name }}                    
                      </div>
                    </template>

                    <template v-slot:cell(customer_id)="data">     
                      <b-avatar :src="data.item.customer.image"
                                v-if="data.item.customer.image">
                      </b-avatar>
                      <b-avatar v-else 
                                icon="building"                                            
                                variant="dark">
                      </b-avatar>     
                      &nbsp;<b>{{data.item.customer.name}}</b>   
                      <b-icon icon="circle-fill" v-if="!data.item.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />               
                    </template>

                    <template v-slot:cell(date_init)="data">
                      {{ moment(data.item.date_init).format('D/M/YYYY') }}
                    </template>
                    
                    <template v-slot:cell(date_end)="data">
                      <div v-if="diffDate(data.item.date_end)==0" class="project-vto-warning-custom">
                        {{ moment(data.item.date_end).format('D/M/YYYY') }}
                      </div>
                      <div v-if="diffDate(data.item.date_end)>0" class="project-vto-success-custom">
                        {{ moment(data.item.date_end).format('D/M/YYYY') }}
                      </div>
                      <div v-if="diffDate(data.item.date_end)<0" class="project-vto-alert-custom">
                        {{ moment(data.item.date_end).format('D/M/YYYY') }}
                      </div>                  
                    </template>
      
                    <template v-slot:cell(employee_id)="data">  
                      <div v-if="data.item.employee">
                        <b>{{ data.item.employee.id }} - {{ data.item.employee.name }}</b>                                    
                      </div>
                      <div v-else>
                        <i>Sin Asignar</i>
                      </div>
                    </template>

                    <template v-slot:cell(hours_estimated)="data">
                      {{ data.item.hours_estimated }} Hs.
                    </template>

                    <template v-slot:cell(status)="data">
                      <b-badge variant="info" v-if="data.item.status=='active'">Activo</b-badge>
                      <b-badge variant="warning" v-if="data.item.status=='pause'">Pausado</b-badge>
                      <b-badge variant="success" v-if="data.item.status=='finished'">Finalizado</b-badge>
                      <b-badge variant="danger" v-if="data.item.status=='canceled'">Cancelado</b-badge>
                    </template>

                    <template v-slot:cell(equipo)="data">
                      <div v-if="data.item.equipo">                    
                        <b-badge  variant="secondary"
                                  class="mr-1 badge-module-employee-custom"
                                  v-for="value in filtroEmployee(data.item.equipo)"
                                  :key="value.id"
                                  :title="getNameEmployee(value)">
                          {{getInitialsNameEmployee(value)}}
                        </b-badge>                    
                      </div>
                    </template>

                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right text="" size="sm" variant="outline-dark" class="pull-right">
                        <b-dropdown-item @click="irDetailHours(data.item)">
                          <b-icon icon="clock-history"></b-icon> Horas
                        </b-dropdown-item>                          
                        <b-dropdown-item @click="openDetail(data)">
                          <b-icon icon="chevron-compact-down"></b-icon> Resumen
                        </b-dropdown-item>                                                      
                        <b-dropdown-header v-if="!isEmployee">Acciones</b-dropdown-header>                    
                        <b-dropdown-item @click="edit(data.item)" v-if="!isEmployee">
                          <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="remove(data.item)" v-if="!isEmployee">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col>
                <nav>
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="getRowCount(tableWithExpiration.items)"
                                :per-page="tableWithExpiration.perPage"
                                v-model="tableWithExpiration.currentPage" />
                </nav>
              </b-col>   
            </b-row>
          </b-tab>

          <b-tab :title="getNameTab('without_expiration')" :active="tabs.without_expiration" @click="setActiveTab('without_expiration')">
            <b-row>
              <b-col>
                <b-table class="mb-0 crud-project-table"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableWithoutExpiration.items"
                        :fields="tableWithoutExpiration.fields"                    
                        :current-page="tableWithoutExpiration.currentPage"
                        :per-page="tableWithoutExpiration.perPage"
                        selectable
                        select-mode="single"
                        @row-selected="onRowSelectedWithoutExpiration"                    
                        v-if="tableWithoutExpiration.items.length">

                    <template v-slot:table-colgroup="scope">
                        <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                        >
                    </template> 

                    <template v-slot:row-details="row">
                      <b-card>                    
                        <b-row>
                          <b-col md="6" class="mt-4">    
                            <div>
                              <b-alert show variant="info" v-if="row.item.status=='active'">El proyecto está activo</b-alert>
                              <b-alert show variant="warning" v-if="row.item.status=='pause'">El proyecto está pausado</b-alert>
                              <b-alert show variant="danger" v-if="row.item.status=='canceled'">El proyecto esta cancelado</b-alert>
                              <b-alert show variant="success" v-if="row.item.status=='finished'">El proyecto está finalizado</b-alert>
                            </div>                            
                          </b-col>

                          <b-col md="6">                            
                            <b-row>
                              <b-col md="4">
                                <b-form-select size="sm" v-model="filters.months" :options="opt.months" class="mb-2"></b-form-select>
                              </b-col>
                              <b-col md="4">
                                <b-form-select size="sm" v-model="filters.years" :options="opt.years" class="mb-2"></b-form-select>
                              </b-col>
                              <b-col md="4">
                                <b-button variant="dark" size="sm" @click="filterProjects()">Filtrar</b-button>                                
                              </b-col>
                            </b-row>                            

                            <b-table-simple hover small caption-top responsive>
                              <caption>Resumen de las horas consumidas por cada empleado:</caption>   
                              <b-thead head-variant="dark">
                                <b-tr>
                                  <b-th>Empleado</b-th>
                                  <b-th class="text-center">Horas Consumidas</b-th>
                                  <b-th class="text-right">Costo Prom x Hora</b-th>
                                  <b-th class="text-right">Costo Total</b-th>                            
                                </b-tr>
                              </b-thead>
                              <b-tbody>
                                <b-tr v-for="(item, index) in arr.projectsHours" :key="index">
                                  <b-td>{{item.data.name}}</b-td>
                                  <b-td class="text-center">{{Intl.NumberFormat('es-AR',{minimumFractionDigits:2,maximumFractionDigits:2}).format( sumTotalHoursByEmployee(item.group) )}}</b-td>
                                  <b-td class="text-right">{{Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format( costPromHoursEmployee(item.group) )}}</b-td>
                                  <b-td class="text-right">{{Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format( costTotalHoursEmployee(item.group) )}}</b-td>
                              </b-tr>
                              </b-tbody>
                              <b-tfoot>
                                <b-tr>
                                  <b-td colspan="7" variant="secondary" class="text-right">
                                    Total horas: <b>{{Intl.NumberFormat('es-AR',{minimumFractionDigits:2,maximumFractionDigits:2}).format( sumTotalHours(arr.projectsHours) )}}</b>
                                  </b-td>
                                </b-tr>
                                <b-tr>
                                  <b-td colspan="7" variant="secondary" class="text-right">
                                    Promedio de horas: <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format(promTotalHours(arr.projectsHours))}} </b>
                                  </b-td>
                                </b-tr>                            
                                <b-tr>
                                  <b-td colspan="7" variant="secondary" class="text-right">                                
                                    Total costo: <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format(sumTotalCost(arr.projectsHours))}}</b>
                                  </b-td>
                                </b-tr>
                              </b-tfoot>
                            </b-table-simple>                           
                          </b-col>
                        </b-row>
                      </b-card>
                    </template>

                    <template v-slot:cell(id)="data">
                      <b>{{ data.item.id }}</b>
                    </template>

                    <template v-slot:cell(name)="data">
                      <div v-b-popover.hover.top="data.item.description" title="Descripción" v-if="data.item.description">                    
                        <b-icon icon="card-text" class="mr-1" style="font-size:17px;"></b-icon>
                        {{ data.item.name }}                    
                      </div>                  
                      <div v-else>
                        {{ data.item.name }}                    
                      </div>
                    </template>

                    <template v-slot:cell(customer_id)="data">     
                      <b-avatar :src="data.item.customer.image"
                                v-if="data.item.customer.image">
                      </b-avatar>
                      <b-avatar v-else 
                                icon="building"                                            
                                variant="dark">
                      </b-avatar>     
                      &nbsp;<b>{{data.item.customer.name}}</b>   
                      <b-icon icon="circle-fill" v-if="!data.item.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />               
                    </template>

                    <template v-slot:cell(date_init)="data">
                      {{ moment(data.item.date_init).format('D/M/YYYY') }}
                    </template>
                    
                    <template v-slot:cell(employee_id)="data">  
                      <div v-if="data.item.employee">
                        <b>{{ data.item.employee.id }} - {{ data.item.employee.name }}</b>                                    
                      </div>
                      <div v-else>
                        <i>Sin Asignar</i>
                      </div>
                    </template>

                    <template v-slot:cell(status)="data">
                      <b-badge variant="info" v-if="data.item.status=='active'">Activo</b-badge>
                      <b-badge variant="warning" v-if="data.item.status=='pause'">Pausado</b-badge>
                      <b-badge variant="success" v-if="data.item.status=='finished'">Finalizado</b-badge>
                      <b-badge variant="danger" v-if="data.item.status=='canceled'">Cancelado</b-badge>
                    </template>

                    <template v-slot:cell(equipo)="data">
                      <div v-if="data.item.equipo">                    
                        <b-badge  variant="secondary"
                                  class="mr-1 badge-module-employee-custom"
                                  v-for="value in filtroEmployee(data.item.equipo)"
                                  :key="value.id"
                                  :title="getNameEmployee(value)">
                          {{getInitialsNameEmployee(value)}}                                                            
                        </b-badge>                    
                      </div>
                    </template>

                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right text="" size="sm" variant="outline-dark" class="pull-right">
                        <b-dropdown-item @click="irDetailHours(data.item)">
                          <b-icon icon="clock-history"></b-icon> Horas
                        </b-dropdown-item>                          
                        <b-dropdown-item @click="openDetail(data)">
                          <b-icon icon="chevron-compact-down"></b-icon> Resumen
                        </b-dropdown-item>                                                      
                        <b-dropdown-header v-if="!isEmployee">Acciones</b-dropdown-header>                    
                        <b-dropdown-item @click="edit(data.item)" v-if="!isEmployee">
                          <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="remove(data.item)" v-if="!isEmployee">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col>
                <nav>
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="getRowCount(tableWithoutExpiration.items)"
                                :per-page="tableWithoutExpiration.perPage"
                                v-model="tableWithoutExpiration.currentPage" />
                </nav>
              </b-col>   
            </b-row>
          </b-tab>

        </b-tabs>

      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>         
        </b-row>
      </b-card>
      
      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="lg">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>   
          <b-col md="6">
            <b-form-group label="Modo">
              <b-form-select v-model="crud.form.mode" :options="arr.mode" @input="changeMode()"></b-form-select>              
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Estado">
              <b-form-select v-model="crud.form.status" :options="arr.status"></b-form-select>              
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Nombre">
              <b-form-input type="text" size="sm" v-model="crud.form.name" placeholder="Ingresar un nombre"> </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Descripción">
              <b-form-textarea                                
                v-model="crud.form.description"
                size="sm"
                placeholder="Ingresa la descripción de la tarea"
              ></b-form-textarea>
            </b-form-group>          
          </b-col>

          <b-col md="6">
            <FindObject render="search"
                        type="customers" 
                        @select-object="loadCustomer($event)" 
                        :valueID="crud.form.customer_id"
                        :where="conditionCustomersActive"/>
          </b-col>  

          <b-col md="6" v-if="crud.form.mode=='with_expiration'">
            <b-form-group label="Horas Estimadas" description="Horas total de estimación para el proyecto">
              <b-form-input type="number" size="sm" v-model="crud.form.hours_estimated" placeholder="Horas Estimadas"> </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Fecha de Inicio">
              <b-form-datepicker size="sm" v-model="crud.form.date_init" placeholder="Fecha de Inicio" local="es"></b-form-datepicker>                      
            </b-form-group>            
          </b-col>

          <b-col md="6" v-if="crud.form.mode=='with_expiration'">
            <b-form-group label="Fecha de Finalización">
              <b-form-datepicker size="sm" v-model="crud.form.date_end" placeholder="Fecha de Finalización" local="es"></b-form-datepicker>                      
            </b-form-group>
          </b-col>   

          <b-col md="6">
            <FindObject type="employee" 
                        @select-object="loadEmployee($event)" 
                        :valueID="crud.form.employee_id" 
                        tag="Project Manager" 
                        :where="conditionEmployeActive" />
          </b-col>  

          <b-col md="6">
            <b-form-group label="Equipo" description="Seleccione todos los empleados que van a poder visualizar el proyecto">
              <b-form-select v-model="crud.form.equipo" :options="arr.employee" multiple :select-size="4"></b-form-select>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill mt-1" @click="crud.form.equipo=[]">
                <i class="icon-refresh"></i>
                Borrar Selección
              </b-button>
            </b-form-group>            
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Funciones from '@/handler/funciones'
  import moment from 'moment' 
  import FindObject from '@/components/inc/find/findObject'
  import Storage from '@/handler/storageLocal'

  export default {
    components: {
      FindObject
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.PROYECTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudProject',
          elements: {}
        },
        table: {
          mostrarFiltros: true,          
        },                 
        tableWithExpiration : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class:"align-middle", width:"3%"},            
            {key: 'customer_id', label: 'Cliente', sortable: true, class:"align-middle", width:"17%"},
            {key: 'name', label: 'Nombre',class:"align-middle", width:"24%"},
            {key: 'date_init', label: 'Inicio', class: 'text-center align-middle', sortable: true, width:"6%"},
            {key: 'date_end', label: 'Fin', class: 'text-center align-middle', sortable: true, width:"6%"},
            {key: 'hours_estimated', label: 'Estimación', class: 'text-center align-middle', sortable: true, width:"6%"},
            {key: 'employee_id', label: 'Project Manager', sortable: true, class:"align-middle", width:"15%"},
            {key: 'equipo', label: 'Equipo', class:"align-middle", width:"15%"},
            {key: 'status', label: 'Estado', class:"align-middle text-center", width:"5%"},          
            {key: 'f_action', label:'', class:"align-middle", width:"3%"},
          ],                    
          currentPage: 1,
          perPage: 50,
        },
        tableWithoutExpiration : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class:"align-middle", width:"3%"},            
            {key: 'customer_id', label: 'Cliente', sortable: true, class:"align-middle", width:"17%"},
            {key: 'name', label: 'Nombre',class:"align-middle", width:"20%"},
            {key: 'date_init', label: 'Inicio', class: 'text-center align-middle', sortable: true, width:"7%"},
            {key: 'employee_id', label: 'Project Manager', sortable: true, class:"align-middle", width:"15%"},
            {key: 'equipo', label: 'Equipo', class:"align-middle", width:"30%"},
            {key: 'status', label: 'Estado', class:"align-middle text-center", width:"5%"},          
            {key: 'f_action', label:'', class:"align-middle", width:"3%"},
          ],                    
          currentPage: 1,
          perPage: 50,
        },        
        crud: {
          form: {
            id: 0,
            mode: 'with_expiration',
            name: '',
            customer_id: 0,
            customer: null,
            date_init: '',
            date_end: '',
            hours_estimated: 0,
            employee_id: 0,
            employee: null,
            equipo: [],
            description: '',
            status:'active'
          },          
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
        },
        arr: {
          mode: [
            {value:'with_expiration', text: 'Con Vencimiento'},
            {value:'without_expiration', text: 'Sin Vencimiento'},
          ],
          employee: [],
          employeeFilter: [],
          projectsWithExpiration: [],
          projectsWithoutExpiration: [],
          projectsHours: [],
          status: [
            {value:'active', text: 'Activo'},
            {value:'pause', text: 'Pausado'},
            {value:'finished', text: 'Finalizado'},
            {value:'canceled', text: 'Cancelado'},
          ],
          select:{
            employees:[],
            customers:[],
            status:[
              {code:'active', label: 'Activo'},
              {code:'pause', label: 'Pausado'},
              {code:'finished', label: 'Finalizado'},
              {code:'canceled', label: 'Cancelado'},
            ]
          }
        },
        filters: {
          customers: [],
          employees: [],
          status: [
            {code:'active', label: 'Activo'}
          ],
          date_end: '',
          months: '',
          years: '',
        },
        tabs: {
          with_expiration: true,
          without_expiration: false,
        },
        opt: {
          months: [],
          years: [],
        },                        
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    }, 
    mounted() {                                        
      this.filterLoad()
      this.filterProjects()

      this.restoreStatusTabsStorage()
    },   
    computed: {
      isEmployee(){
        if(Helper.getEmployee()) {
          if(Helper.getEmployee().permission_admin_projects) {
            return false  
          } else {
            return true
          }                    
        } else {
          return false
        }
      },
      conditionCustomersActive(){
        return [{field: 'active', condition: true}];
      },   
      conditionEmployeActive(){
        return [{field: 'active', condition: true}];
      },
    },
    methods: {
      getRowCount (items) {
        return items.length
      },  
      onRowSelectedWithExpiration(item) {                   
        if(item.length) {        
          this.arr.projectsHours = Funciones.groupJSON(item[0].hours,'employee','id')

          // CON VENCIMIENTO
          this.arr.projectsWithExpiration.forEach((element, index) => {
            if(item.length){
              if(element.id == item[0].id) {              
                this.tableWithExpiration.rowSelected = index                
                this.filterProjects()
              }
            }
          })

          this.tableWithoutExpiration.rowSelected = null
        }
      },
      onRowSelectedWithoutExpiration(item) {  
        if(item.length) {        
          this.arr.projectsHours = Funciones.groupJSON(item[0].hours,'employee','id')

          // SIN VENCIMIENTO
          this.arr.projectsWithoutExpiration.forEach((element, index) => {
            if(item.length){
              if(element.id == item[0].id) {              
                this.tableWithoutExpiration.rowSelected = index
                this.filterProjects()
              }
            }
          })          
          
          this.tableWithExpiration.rowSelected = null                
        }
      },     
      openDetail(data) {               
        if(data.item.mode == 'with_expiration') {
          this.onRowSelectedWithExpiration([data.item])
        }

        if(data.item.mode == 'without_expiration') {
          this.onRowSelectedWithoutExpiration([data.item])
        }
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.mode = 'with_expiration'        
        this.crud.form.name = ''        
        this.crud.form.customer_id = 0  
        this.crud.form.customer = null  
        this.crud.form.date_init = ''  
        this.crud.form.date_end = ''
        this.crud.form.hours_estimated = 0  
        this.crud.form.employee_id = 0  
        this.crud.form.employee = null  
        this.crud.form.equipo = []        
        this.crud.form.description = ''        
        this.crud.form.status = 'active'        

        this.modal.form.title = "Nuevo Proyecto"
        this.modal.form.active = true
      },
      edit(item) {        
        this.crud.form.id = item.id
        this.crud.form.mode = item.mode
        this.crud.form.name = item.name                   
        this.crud.form.customer_id = item.customer.id  
        this.crud.form.customer = item.customer  
        this.crud.form.date_init = item.date_init
        this.crud.form.date_end = item.date_end
        this.crud.form.hours_estimated = item.hours_estimated  
        
        if(item.employee) {
          this.crud.form.employee = item.employee
          this.crud.form.employee_id = item.employee.id
        } else {
          this.crud.form.employee = null
          this.crud.form.employee_id = 0
        }
        
        if(item.equipo) {
          this.crud.form.equipo = item.equipo.split(',')
        } else {
          this.crud.form.equipo = []
        }
        
        this.crud.form.description = item.description
        this.crud.form.status = item.status
        
        this.modal.form.title = "Editar Proyecto"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Proyecto',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarProyectos(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              
              this.filterProjects()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();
        if (this.crud.form.id) {
          var result = serviceAPI.editarProyectos(this.crud.form);
        } else {
          var result = serviceAPI.agregarProyectos(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          
          this.filterProjects()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      changeMode(){        
        if(this.crud.form.mode == 'without_expiration') {
          this.crud.form.date_end = ''
          this.crud.form.hours_estimated = 0
        } 
      },       
      loadCustomer (object) {
        if(object){
          this.crud.form.customer = object
          this.crud.form.customer_id = object.id
        } else {
          this.crud.form.customer = null
          this.crud.form.customer_id = 0
        }
      },  
      loadEmployee (object) {        
        if(object){
          this.crud.form.employee = object
          this.crud.form.employee_id = object.id        
        } else {
          this.crud.form.employee = null
          this.crud.form.employee_id = 0
        }

        this.loadArrEmployee()
      },
      loadArrEmployee() {        
        var result = serviceAPI.obtenerEmpleado()        
        result.then((response) => {
          var data = response.data    
          
          this.arr.employee = []    
          data.forEach(element => {
            if(this.crud.form.employee) {
              if(element.id != this.crud.form.employee.id) {
                if(element.active) {
                  this.arr.employee.push({ value: element.id, text: element.name })
                }              
              } 
            } else {
              this.arr.employee = []
            }           
          });          
        })              
      },      
      loadArrEmployeeFilter() {
        var result = serviceAPI.obtenerEmpleado()        
        result.then((response) => {          
          var data = response.data                
          this.arr.employeeFilter = data            
        })              
      },
      filtroEmployee(row) {        
        var arr = []
        row.split(',').forEach(element => {          
          arr.push(element)          
        })

        var arrActive = []
        if(arr) {
          arr.forEach(element => {            
            this.arr.employeeFilter.forEach(element1 => {
              
              if(element1.active) {
                if(parseInt(element)==element1.id) {
                  arrActive.push(element)
                }
              }
            })

          });
        }

        return arrActive
      },  
      getNameEmployee(value) {        
        var val = ''
        this.arr.employeeFilter.forEach(element => {                
          if(parseInt(value)==element.id) {            
            val = element.name
          }
        })
        return val
      },   
      getInitialsNameEmployee(value) {        
        var val = ''
        this.arr.employeeFilter.forEach(element => {                
          if(parseInt(value)==element.id) {            
            val = element.name
          }
        })

        var fullName = val.split(' ')
        var initialsName = ''
        fullName.forEach(element => {
          initialsName = initialsName + element.charAt(0)
        });

        if(!initialsName) {
          initialsName = val
        }        
        return initialsName
      },          
      sumTotalHoursByEmployee(arr) {        
        var sum = 0
        arr.forEach(element => {
          sum = sum + parseFloat(element.hours)
        });
        return sum
      },
      costPromHoursEmployee(arr) {        
        var sum = 0
        var cant = arr.length

        arr.forEach(element => {
          sum = sum + parseFloat(element.price)
        });
        return sum / cant        
      },
      costTotalHoursEmployee(arr) {        
        var sum = 0

        arr.forEach(element => {
          sum = sum + parseFloat(element.price_total)
        });
        return sum
      },
      sumTotalHours(arr) {
        var sum = 0        
        arr.forEach(element => {          
          element.group.forEach(element1 => {
            sum = sum + parseFloat(element1.hours)
          });
        });
        return sum
      },   
      sumTotalCost(arr) {
        var sum = 0        
        arr.forEach(element => {          
          element.group.forEach(element1 => {
            sum = sum + parseFloat(element1.price_total)
          });
        });
        return sum
      },
      promTotalHours(arr) {
        var totalHours = this.sumTotalHours(arr)
        var totalCost = this.sumTotalCost(arr)

        if(totalHours) {
          return totalCost / totalHours
        } else {
          return 0
        }
      },
      diffDateHumanize(date){           
        var dias = this.diffDate(date)

        if(dias == 0) {
          return '<div class="alert alert-warning"><b>El proyecto vence hoy</b></div>'
        }
        if(dias>0) {
          return '<div class="alert alert-success"><b>El proyecto vence ' + moment.duration(dias,'days').humanize(true) + "</b></div>"
        } else {
          return '<div class="alert alert-danger"><b>El proyecto venció ' + moment.duration(dias,'days').humanize(true) + "</b></div>"
        }              
      },
      diffDate(date){
        var vto = moment(date, "YYYY-MM-DD");
        var hoy = moment().startOf('day');        
        var dias = moment.duration(vto.diff(hoy)).asDays()

        return dias
      },
      porcentajeHorasRestantes(val1, val2) {
        if(val2>0) {
          return (val1/val2)*100
        } else {
          return 0
        }

      },
      filterLoad() {
        this.filterLoadCustomers()
        this.filterLoadEmployees()
        this.setFilterEmployeesPermission()
        this.filterMonthsYears()  

        this.restoreFilterStorage() 
      },
      filterLoadCustomers() {
        var result = serviceAPI.obtenerClientes()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.customers = []
          data.forEach(element => {        
            if(element.code) {
              this.arr.select.customers.push({ code: element.id, label: element.code + ' | ' + element.name })            
            } else {
              this.arr.select.customers.push({ code: element.id, label: element.name })            
            }                              
          });          
        })   
      },
      filterLoadEmployees() {
        var result = serviceAPI.obtenerEmpleado()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.employees = []
          data.forEach(element => {            
            this.arr.select.employees.push({ code: element.id, label: element.name })            
          });          
        })   
      },
      filterProjects(){
        this.saveFilterStorage()

        this.tableWithExpiration.items = []
        this.arr.projectsWithExpiration = []
        this.tableWithoutExpiration.items = []      
        this.arr.projectsWithoutExpiration = []

        var result = serviceAPI.filtrarProyectos(this.filters)        
        result.then((response) => {
          var data = response.data
          
          data.forEach(element => {           
            if(element.mode == 'with_expiration') {
              this.tableWithExpiration.items.push(element)
              this.arr.projectsWithExpiration.push(element)
            }

            if(element.mode == 'without_expiration') {
              this.tableWithoutExpiration.items.push(element)      
              this.arr.projectsWithoutExpiration.push(element)
            }          
          });
          
          // CON VENCIMIENTO
          if(this.tableWithExpiration.items.length) {
            if(this.tableWithExpiration.rowSelected!=null) {
              this.tableWithExpiration.items[this.tableWithExpiration.rowSelected]._showDetails = true                
            }              
          }

          // SIN VENCIMIENTO          
          if(this.tableWithoutExpiration.items.length) {
            if(this.tableWithoutExpiration.rowSelected!=null) {

              var item = this.tableWithoutExpiration.items[this.tableWithoutExpiration.rowSelected]              
              this.arr.projectsHours = Funciones.groupJSON(item.hours,'employee','id')             

              this.tableWithoutExpiration.items[this.tableWithoutExpiration.rowSelected]._showDetails = true                
            }               
          }

          this.loadArrEmployeeFilter()
        })           
        .catch(error => {               
          this.$awn.alert(ErrorToken.valid(error));
        })          
      },
      filterMonthsYears() {
        // prepare months
          this.opt.months.push({value:'01', text:'Enero'})
          this.opt.months.push({value:'02', text:'Febrero'})
          this.opt.months.push({value:'03', text:'Marzo'})
          this.opt.months.push({value:'04', text:'Abril'})
          this.opt.months.push({value:'05', text:'Mayo'})
          this.opt.months.push({value:'06', text:'Junio'})
          this.opt.months.push({value:'07', text:'Julio'})
          this.opt.months.push({value:'08', text:'Agosto'})
          this.opt.months.push({value:'09', text:'Septiembre'})
          this.opt.months.push({value:'10', text:'Octubre'})
          this.opt.months.push({value:'11', text:'Noviembre'})
          this.opt.months.push({value:'12', text:'Diciembre'})
        // fin

        // prepare yeras
          var yearNow = moment().format('YYYY')
          var yearLimit = 2015

          for (let index = yearLimit; index <= yearNow; index++) {
            this.opt.years.push({
              value: index,
              text: index,
            })
          } 
          this.opt.years = this.opt.years.reverse()    
        // fin
      }, 
      setFilterEmployeesPermission() {
        if(this.isEmployee) {
          var reg = Helper.getEmployee()
          this.filters.employees.push(
            {label: reg.name, code: reg.id}
          )
        }
      },
      irDetailHours(data) {        
        this.$router.push({ name: 'ProjectStaffDetailHoursProjects', params: { projectID: data.id } })                  
      },

      //TABS
      disableAllTabs() {
        this.tabs.with_expiration = false
        this.tabs.without_expiration = false
      },
      setActiveTab(value) {
        this.disableAllTabs()

        switch (value) {
          case 'with_expiration':
            this.tabs.with_expiration = true
            break;
        
          case 'without_expiration':
            this.filters.months = moment().format('MM')
            this.filters.years = moment().format('YYYY')
            this.filterProjects()
            this.tabs.without_expiration = true
            break;

          default:
            this.tabs.with_expiration = true
            break;
        }

        this.saveStatusTabsStorage()
      },      
      getNameTab(name) {
        if(name == 'with_expiration') {
          let cant = this.tableWithExpiration.items.length
          if(cant) {
            return 'Proyectos con Vencimiento (' + cant + ')'
          } else {
            return 'Proyectos con Vencimiento'
          }          
        }    
        
        if(name == 'without_expiration') {
          let cant = this.tableWithoutExpiration.items.length
          if(cant) {
            return 'Proyectos sin Vencimiento (' + cant + ')'
          } else {
            return 'Proyectos sin Vencimiento'
          }          
        }            
      },

      // TABS SAVE
      saveStatusTabsStorage() {
        Storage.setValue('tabs_view_projects', JSON.stringify(this.tabs))
      },
      restoreStatusTabsStorage() {
        if(Storage.getValue('tabs_view_projects')) {
          this.tabs = JSON.parse(Storage.getValue('tabs_view_projects'))
        }         

        this.filters.months = moment().format('MM')
        this.filters.years = moment().format('YYYY')
      },       

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_view_projects', JSON.stringify(this.filters))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_view_projects')) {
          this.filters = JSON.parse(Storage.getValue('filter_view_projects'))
        }         
      },        
    }      
  }
</script>
<style>
  .badge-module-employee-custom {
    font-size: 14px;
    color: #504f4f;
    background-color: transparent;
    border-color: #c8ced3;
    border-style: solid;
    border-width: 0.5px;    
  }
  .project-vto-alert-custom {
    font-weight: 700;
    color:brown    
  }
  .project-vto-success-custom {
    font-weight: 700;
    color:darkgreen
  }  
  .project-vto-warning-custom {
    font-weight: 700;
    color:darkgoldenrod
  }  
  .crud-project-table {
    overflow: auto;
  }      
</style>
<style scoped>
  .b-avatar {
    width: 25px;
    height: 25px;    
  }  
</style>