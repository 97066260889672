import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerProyectos() {
    var url = ConfigAPI.baseURL + "projects/projects" + Session.getToken();
    return instance.get(url);
  },
  mostrarProyectos(id) {
    var url = ConfigAPI.baseURL + "projects/projects/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarProyectos(data) {
    var params = data
    var url = ConfigAPI.baseURL + "projects/projects" + Session.getToken();
    return instance.post(url,params);
  },
  editarProyectos(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "projects/projects/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarProyectos(id) {
    var url = ConfigAPI.baseURL + "projects/projects/"+ id + Session.getToken();
    return instance.delete(url);
  },
  filtrarProyectos(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "projects/projects/filter/query" + Session.getToken();
    return instance.post(url,params);
  },
  

  obtenerActividad() {
    var url = ConfigAPI.baseURL + "projects/activity" + Session.getToken();
    return instance.get(url);
  },
  mostrarActividad(id) {
    var url = ConfigAPI.baseURL + "projects/activity/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarActividad(data) {
    var params = data
    var url = ConfigAPI.baseURL + "projects/activity" + Session.getToken();
    return instance.post(url,params);
  },
  editarActividad(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "projects/activity/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarActividad(id) {
    var url = ConfigAPI.baseURL + "projects/activity/"+ id + Session.getToken();
    return instance.delete(url);
  },

  obtenerEmpleado() {
    var url = ConfigAPI.baseURL + "employee/employee" + Session.getToken();
    return instance.get(url);
  },  

  obtenerClientes() {
    var url = ConfigAPI.baseURL + "customers" + Session.getToken();
    return instance.get(url);
  },

  obtenerHorasByMes(data) {
    var params = data
    var url = ConfigAPI.baseURL + "projects/hours/filter/month" + Session.getToken();
    return instance.post(url,params);
  },
  obtenerHorasByDia(data) {
    var params = data
    var url = ConfigAPI.baseURL + "projects/hours/filter/day" + Session.getToken();
    return instance.post(url,params);
  }, 
  obtenerHorasByEmployee(data) {
    var params = data
    var url = ConfigAPI.baseURL + "projects/hours/filter/employee" + Session.getToken();
    return instance.post(url,params);
  },        
  agregarHora(data) {
    var params = data
    var url = ConfigAPI.baseURL + "projects/hours" + Session.getToken();
    return instance.post(url,params);
  },
  editarHora(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "projects/hours/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarHora(id) {
    var url = ConfigAPI.baseURL + "projects/hours/"+ id + Session.getToken();
    return instance.delete(url);
  },

  obtenerEmpleadoByStaff(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "employee/employee/filter/staff" + Session.getToken();    
    return instance.post(url,params);        
  },
  obtenerProyectosFiltrandoEquiposByEmpleado(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "projects/projects/filter/equipo" + Session.getToken();
    return instance.post(url,params);        
  },  

  obtenerHorasByProject(data) {
    var params = data
    var url = ConfigAPI.baseURL + "projects/hours/filter/project" + Session.getToken();
    return instance.post(url,params);
  },  
  obtenerHorasByDiaByProyecto(data) {
    var params = data
    var url = ConfigAPI.baseURL + "projects/hours/filter/day/project" + Session.getToken();
    return instance.post(url,params);
  },   
}

export default servicesAPI;
